<template>
  <div class="flex h-full flex-col text-center font-avenir text-primary antialiased">
    <NavBar />
    <router-view />
  </div>
</template>

<script>
import NavBar from '@/components/NavBar'
export default {
  name: 'App',
  components: {
    NavBar,
  },
  setup() {
    return {}
  },
}
</script>
