const NewRelicUIMonitoringPlugin = {
  install(app, options) {
    if (window.NREUM) {
      console.info('Clearing existing NR')
      window.NREUM = undefined
    }
    window.NREUM = options
    const script = document.createElement('script')
    script.setAttribute('type', 'text/javascript')
    script.setAttribute('crossorigin', 'anonymous')
    script.setAttribute(
      'src',
      'https://cdn.jsdelivr.net/gh/paribaker/new_relic_ui_script@latest/newrelic_script.js',
    )
    document.head.appendChild(script)
  },
}

export default NewRelicUIMonitoringPlugin
