import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import { SET_USER, SET_CAMERA_POSITION, SET_CAMERA_POI_TARGET_LOCK } from './mutation-types'
import { Vector3 } from 'three'
const STORAGE_HASH = 'bvCnoiOxkh'
export const STORAGE_KEY = `alignment_view-${STORAGE_HASH}`

const state = {
  user: null,
  camera: { cameraPosition: null, poiTargetLock: false },
}

const mutations = {
  [SET_USER]: (state, payload) => {
    state.user = payload
  },
  [SET_CAMERA_POSITION]: (state, payload) => {
    state.camera.cameraPosition = payload
  },
  [SET_CAMERA_POI_TARGET_LOCK]: (state, payload) => {
    state.camera.poiTargetLock = payload
  },
}

const actions = {
  setUser({ commit }, user) {
    commit(SET_USER, user)
  },
  setCameraPosition({ commit }, cameraPosition) {
    commit(SET_CAMERA_POSITION, cameraPosition)
  },
  setCameraPoiTargetLock({ commit }, poiTargetLock) {
    commit(SET_CAMERA_POI_TARGET_LOCK, poiTargetLock)
  },
}

const getters = {
  isLoggedIn: (state) => {
    return !!state.user
  },
  user: (state) => {
    return state.user
  },
  token: (state) => {
    return state.user ? state.user.token : null
  },
  cameraPosition: (state) => {
    const pos = state?.camera?.cameraPosition
    if (pos) {
      return new Vector3(pos._x, pos._y, pos._z)
    }
  },
  poiTargetLock: (state) => {
    return state?.camera?.poiTargetLock
  },
}

const store = createStore({
  state,
  mutations,
  actions,
  getters,
  modules: {},
  plugins: [
    createPersistedState({
      key: STORAGE_KEY,
    }),
  ],
})

export default store
