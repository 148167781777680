<template>
  <div class="flex flex-col items-center justify-center w-full flex-grow">
    <section class="max-w-2xl flex items-center flex-col justify-start gap-10 flex-grow py-10">
      <section class="flex items-center justify-center">
        <img src="@/assets/icons/logo.svg" class="h-56 sm:h-72" />
      </section>
      <section class="max-w-2xl flex items-center flex-col justify-center gap-5">
        <h1 class="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
          Welcome to Alignment View!
        </h1>

        <div class="flex items-center justify-center gap-x-6">
          <router-link :to="{ name: 'MapData' }" class="btn--accent">Enter</router-link>
        </div>
      </section>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Home',
  components: {},
  setup() {},
}
</script>
