import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './main.css'
import VueMapboxTs from 'vue-mapbox-ts'
import Vue3DraggableResizable from 'vue3-draggable-resizable'
import NewRelicUIMonitoringPlugin from './plugins/NewRelic/NewRelic'
//default styles
import 'vue3-draggable-resizable/dist/Vue3DraggableResizable.css'

const PROD_ENDPOINTS = [
  'alignment-view-production-0bd9cdaad050.herokuapp.com',
  'app.alignmentview.com',
]
const LOCATION = `${window.location.protocol}//${window.location.host}`
let IS_PROD = false

PROD_ENDPOINTS.forEach((endpoint) => {
  const regex = new RegExp('\\b' + endpoint.replace(/\./g, '\\.') + '\\b', 'i')
  if (regex.test(LOCATION)) {
    IS_PROD = true
    return
  }
})

const PROD_LOADER_CONFIG = {
  accountID: '4258771',
  trustKey: '4258771',
  agentID: '1120255075',
  licenseKey: 'NRJS-78661520a1b205af901',
  applicationID: '1120255075',
}
const STAGING_LOADER_CONFIG = {
  accountID: '4244527',
  trustKey: '4244527',
  agentID: '1134396563',
  licenseKey: 'NRJS-ebaf53e1f5a96b3907b',
  applicationID: '1134396563',
}

const config = {
  init: {
    distributed_tracing: { enabled: true },
    privacy: { cookies_enabled: true },
    ajax: { deny_list: ['bam.nr-data.net'] },
  },
  loader_config: {
    accountID: IS_PROD ? PROD_LOADER_CONFIG.accountID : STAGING_LOADER_CONFIG.accountID,
    trustKey: IS_PROD ? PROD_LOADER_CONFIG.trustKey : STAGING_LOADER_CONFIG.trustKey,
    agentID: IS_PROD ? PROD_LOADER_CONFIG.agentID : STAGING_LOADER_CONFIG.agentID,
    licenseKey: IS_PROD ? PROD_LOADER_CONFIG.licenseKey : STAGING_LOADER_CONFIG.licenseKey,
    applicationID: IS_PROD ? PROD_LOADER_CONFIG.applicationID : STAGING_LOADER_CONFIG.applicationID,
  },
  info: {
    beacon: 'bam.nr-data.net',
    errorBeacon: 'bam.nr-data.net',
    licenseKey: IS_PROD ? PROD_LOADER_CONFIG.licenseKey : STAGING_LOADER_CONFIG.licenseKey,
    applicationID: IS_PROD ? PROD_LOADER_CONFIG.applicationID : STAGING_LOADER_CONFIG.applicationID,
    sa: 1,
  },
}
createApp(App)
  .use(store)
  .use(NewRelicUIMonitoringPlugin, config)
  .use(VueMapboxTs)
  .use(Vue3DraggableResizable)
  .use(router)
  .mount('#app')
